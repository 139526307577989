import {
  DeviceTypeDisplayText,
  Equipment,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  FormText,
  Loader,
  Modal,
} from '@energybox/react-ui-library/dist/components';

import equals from 'ramda/src/equals';
import pick from 'ramda/src/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as ThermostatActions,
  destroy,
  patch,
  clearFormErrors as clearForm,
} from '../../../../actions/thermostats';
import { fetchThermostat } from '../../../../actions/thermostatsFetch';
import EditThermostatForm from '../../../../components/EditThermostatForm';
import ShowDetailPageHeader from '../../../../components/ShowDetailPageHeader';
import { ApplicationState } from '../../../../reducers';
import { EditThermostat } from '../../../../types/Thermostat';
import { renderAPIerror } from '../../../../utils/apiErrorFeedback';
import {
  areThereFormErrors,
  validateThermostatForm,
} from '../../../../utils/formValidation';
import ResourceFullPath from '../../../ResourceFullPath';
import styles from './ShowThermostatPage.module.css';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import ThermostatStatus from '../../../../components/ThermostatStatus';
import { rebootThermostat } from '../../../../actions/superHub';

interface ExtendedEditVenstarThermostat extends EditThermostat {
  equipment?: Equipment | undefined;
  venstarThermostatModel?: string | null | undefined;
  model: ThermostatModelType;
}

type Props = {
  id: number;
  type: ThermostatModelType;
};

const ShowThermostatPage: React.FC<Props> = ({ id, type }) => {
  //** Local State **//
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const [thermostat, setThermostat] = useState<ExtendedEditVenstarThermostat>();

  const [EditThermostat, setEditThermostat] = useState<
    ExtendedEditVenstarThermostat
  >();
  const [formErrorsVisible, setFormErrorsVisible] = useState(false);

  //** Redux **//
  const apiError = useApiError();
  const isPatchLoading = useIsPatchLoading();

  //** Actions **//
  const dispatch = useDispatch();
  const patchThermostat = usePatchThermostat(dispatch, id, EditThermostat);
  const deleteThermostat = useDeleteThermostat(dispatch, id, EditThermostat);
  const formErrors = validateThermostatForm(EditThermostat);
  const isChanged = !equals(thermostat, EditThermostat);

  useEffect(() => {
    dispatch(clearForm());
  }, []);

  useEffect(() => {
    fetchThermostat(id, type).then(res => {
      const fields: ExtendedEditVenstarThermostat = {
        ...editableFields(res),
        equipment: res.equipment,
        siteId: res.networkGroup?.siteId,
        venstarThermostatModel: res.venstarThermostatModel,
        model: res.model,
      };
      setThermostat(fields);
      setEditThermostat(fields);
    });
  }, [id]);

  const onChange = (field: string, value: string | number) => {
    const updatedFields = {
      ...(EditThermostat as ExtendedEditVenstarThermostat),
      [field]: value,
    };

    setEditThermostat(updatedFields);
  };

  const onSelectSite = (value: number) => {
    const updatedFields = {
      ...(EditThermostat as ExtendedEditVenstarThermostat),
      siteId: value,
      spaceId: -1,
      networkGroupId: -1,
    };
    setEditThermostat(updatedFields);
  };

  const onPatch = () => {
    if (areThereFormErrors(formErrors)) {
      setFormErrorsVisible(true);
    } else {
      patchThermostat();
      setThermostat({
        ...(EditThermostat as ExtendedEditVenstarThermostat),
      });
      setFormErrorsVisible(false);
    }
  };

  const deletePrompt = () => {
    const actions = (
      <>
        <Button variant="text" onClick={() => setIsDeletePromptOpen(false)}>
          Cancel
        </Button>
        <Button onClick={deleteThermostat}>Delete</Button>
      </>
    );

    return (
      <Modal onClose={() => setIsDeletePromptOpen(false)} actions={actions}>
        <p className={styles.textAlignCenter}>
          Are you sure you want to delete{' '}
          {thermostat ? <b>{thermostat.title}</b> : 'this thermostat'}?
        </p>
        {EditThermostat &&
          renderAPIerror(
            apiError,
            ThermostatActions.DELETE_THERMOSTAT_DEVICE_ERROR
          )}
      </Modal>
    );
  };

  const onCancel = () => {
    setEditThermostat(thermostat);
  };

  const isVenstarThermostat = thermostat => {
    return thermostat.model == ThermostatModelType.VENSTAR_THERMOSTAT;
  };

  const getThermostatLabel = thermostat => {
    if (isVenstarThermostat(thermostat)) {
      return thermostat?.venstarThermostatModel
        ? `Venstar ${thermostat.venstarThermostatModel}`
        : undefined;
    } else {
      return `Energybox`;
    }
  };

  const listOptions = [
    ...(thermostat && !isVenstarThermostat(thermostat)
      ? [
          {
            onSelect: () => dispatch(rebootThermostat(id)),
            title: `Reboot ${DeviceTypeDisplayText.THERMOSTAT}`,
          },
        ]
      : []),
    {
      onSelect: () => setIsDeletePromptOpen(true),
      title: `Delete ${DeviceTypeDisplayText.THERMOSTAT}`,
      isDeleteItem: true,
      type: [''],
    },
  ];

  return (
    <>
      {thermostat && (
        <ShowDetailPageHeader
          name={thermostat.title}
          description={<ResourceFullPath resourceId={id} />}
          resourceName={DeviceTypeDisplayText.THERMOSTAT}
          listOptions={listOptions}
          siteId={thermostat.siteId}
        />
      )}
      <div className={styles.content}>
        {EditThermostat && (
          <Card>
            <CardContent className={styles.cardGrid}>
              <CardTitle className={styles.cardTitle}>
                General Information
              </CardTitle>

              <div>
                <EditThermostatForm
                  fields={EditThermostat}
                  onChange={onChange}
                  onSelectSite={onSelectSite}
                  formErrorsVisible={formErrorsVisible}
                  formErrors={formErrors}
                  isEbThermostat={!isVenstarThermostat(EditThermostat)}
                  equipment={thermostat?.equipment}
                  thermostatModel={getThermostatLabel(thermostat)}
                />
                {renderAPIerror(
                  apiError,
                  ThermostatActions.PATCH_THERMOSTAT_DEVICE_ERROR
                )}
                {!isVenstarThermostat(EditThermostat) && (
                  <FormText>
                    <div>* Mandatory fields</div>
                  </FormText>
                )}
              </div>
            </CardContent>

            {isChanged && (
              <CardActions>
                <Button variant="text" onClick={onCancel} children="Cancel" />
                <Button disabled={isPatchLoading} onClick={onPatch}>
                  {isPatchLoading ? (
                    <Loader size={16} variant="secondary" />
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </CardActions>
            )}
          </Card>
        )}
      </div>
      <div className={styles.statusContainer}>
        {EditThermostat && !isVenstarThermostat(EditThermostat) && (
          <>
            <ThermostatStatus
              fields={EditThermostat}
              isEbThermostat={true}
              equipment={thermostat?.equipment}
            />
          </>
        )}
      </div>

      {isDeletePromptOpen && deletePrompt()}
    </>
  );
};

const useApiError = () => {
  return useSelector(({ thermostats }: ApplicationState) => {
    return thermostats.apiError;
  });
};

const useIsPatchLoading = () => {
  return useSelector(({ thermostats }: ApplicationState) => {
    return thermostats.patchThermostatIsLoading;
  });
};

const useDeleteThermostat = (dispatch, id: string | number, payload: any) => {
  return useCallback(() => {
    dispatch(destroy(id));
  }, [dispatch, id]);
};

const usePatchThermostat = (dispatch, id: string | number, payload) => {
  return useCallback(() => {
    const normalizedPayload = editableFields(payload);
    dispatch(patch(id, normalizedPayload));
  }, [dispatch, id, payload]);
};

const editableFields = (thermostat: Thermostat) => {
  return pick(
    [
      'title',
      'description',
      'uuid',
      'model',
      'spaceId',
      'networkGroupId',
      'wirelessTemperatureSensorsCount',
    ],
    thermostat
  );
};

export default ShowThermostatPage;
