import {
  FormText,
  InputField,
  Label,
  Button,
  Loader,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  Edge,
  Gateway,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';

import { ChangeEvent, useEffect, useState } from 'react';
import SelectEdgeControlDevice from '../../containers/Selects/SelectEdgeControlDevice';
import SelectSite from '../../containers/Selects/SelectSite';
import ModalFormContent from '../ModalFormContent';
import styles from './EditNetworkGroupForm.module.css';
import {
  lookupMacAddress,
  setInvalidSerialNumberError,
  renderNewIotodFields,
} from '../../actions/iotod';
import { ApplicationState } from '../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { isValidSerialNumber } from '../../utils/regexValidators';
import {
  EdgeDeviceModel,
  NetworkGroup,
} from '@energybox/react-ui-library/dist/types/NetworkGroup';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import { CircularProgress } from '@material-ui/core';
import { BalenaDeviceStatus } from '../../reducers/balena';
import SelectSpace from '../../containers/Selects/SelectSpace';
import { newGateway } from '../../actions/gateways';
import {
  clearFormErrors,
  patchField,
  reset,
  updateField,
} from '../../actions/network_groups';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { isValidUUID } from '../../utils/ebThermostat';
import { getBalenaDeviceByUUID } from '../../actions/balena';
import { values, prop } from 'ramda';

interface Props {
  onChange: (field: string, value: any) => void;
  updateHub?: (id: string, field: string, value: any) => void;
  formErrors: GenericErrors;
  title: string;
  description: string;
  siteId?: number;
  spaceId?: number;
  edge: Edge;
  isChanged?: boolean;
  formErrorsVisible: boolean;
  lockSiteId?: number;
  originalNetworkGroup?: NetworkGroup; // undefined implies new Network Group
  superHub?: Gateway | ControlBoard;
  runningRelease?: string;
  balenaStatus?: BalenaDeviceStatus;
}

const LOOKUP_MAC_ADDRESS = 'Lookup MAC#' as const;

const EditNetworkGroupForm = ({
  onChange,
  updateHub,
  title,
  description,
  lockSiteId,
  siteId = -1,
  spaceId = -1,
  edge,
  isChanged,
  formErrors = {},
  formErrorsVisible,
  originalNetworkGroup,
  superHub,
  balenaStatus,
}: Props) => {
  const [siteIdInState, setSiteId] = useState(lockSiteId || siteId);
  const [spaceIdInState, setSpaceId] = useState<number>(
    superHub?.spaceId || spaceId
  );
  const [serialNumberValue, setSerialNumberValue] = useState<string>('');
  const [northUuid, setNorthUuid] = useState<string>();
  const [southUuid, setSouthUuid] = useState<string>(superHub?.uuid || '');
  const [formattedUuid, setFormattedUuid] = useState<string>(
    edge?.uuid ? edge.uuid : ''
  );

  const runningRelease = balenaStatus?.runningRelease?.[0]?.raw_version;
  const targetRelease = balenaStatus?.targetRelease?.[0]?.raw_version;

  const isUpdating =
    balenaStatus?.overallStatus === 'updating' ||
    runningRelease !== targetRelease;
  const updateProgress = balenaStatus?.overallProgress;

  const iotodState = useSelector((state: ApplicationState) => state.iotod);
  const latestRelease = useSelector(
    (state: ApplicationState) =>
      state.balena?.firmwareReleases?.[0]?.raw_version
  );
  const balenaStatusByUUID = useSelector(
    (state: ApplicationState) => state.balena?.statusByUuid
  );
  const { lookupMACAddressLoading, lookupMACAddressError } = useSelector(
    (state: ApplicationState) => state.balena
  );
  const createdSpaceId = useSelector(
    ({ spaces }: ApplicationState) => spaces.createdId
  );

  const dispatch = useDispatch();
  const serialNumber = serialNumberValue || edge?.serialNumber || '';

  const isForSuperHub = edge.edgeDevice === 'EB_SUPER_HUB';
  const isNewSuperHub = !!!superHub;

  useEffect(() => {
    const balenaStatusArray = values(balenaStatusByUUID);
    const foundDevice = balenaStatusArray.find(
      item => item.macNorthUUID === northUuid
    );
    const lookupSouthUUID = prop('macSouthUUID', foundDevice);
    const lookupSerialNumber = prop('uuid', foundDevice);
    if (lookupSouthUUID) {
      setSerialNumberValue(lookupSerialNumber);
      onChange('edge.serialNumber', lookupSerialNumber);
      setSouthUuid(lookupSouthUUID);
      onChange('southUuid', lookupSouthUUID);
      onChange('edge.uuid', northUuid);
      onChange('northUuid', northUuid);
    }
  }, [balenaStatusByUUID]);

  useEffect(() => {
    // Case where form is rest to initial state
    if (isChanged !== undefined && !isChanged && siteId) {
      setNorthUuid(isForSuperHub ? edge.uuid : undefined);
      setSiteId(siteId);
    }
  }, [siteId, isChanged]);

  useEffect(() => {
    dispatch(renderNewIotodFields());
    if (isForSuperHub) setNorthUuid(edge.uuid);
  }, []);

  useEffect(() => {
    if (createdSpaceId > 0) {
      setSpaceId(createdSpaceId);
      onChange('spaceId', createdSpaceId);
    }
  }, [createdSpaceId]);

  useEffect(() => {
    if (iotodState?.macAddress && serialNumberValue && !isForSuperHub) {
      onChange('edge.uuid', iotodState?.macAddress);
    }
  }, [iotodState?.macAddress, serialNumberValue]);

  useEffect(() => {
    if (southUuid && isForSuperHub && updateHub) {
      if (!superHub) {
        dispatch(updateHub('new', 'uuid', southUuid));
      }
    }
  }, [superHub, southUuid, isForSuperHub]);

  useEffect(() => {
    if (edge?.uuid) {
      if (isForSuperHub) {
        setNorthUuid(edge.uuid);
      } else {
        setFormattedUuid(edge.uuid);
      }
    }
  }, [edge?.uuid]);

  useEffect(() => {
    if (superHub && superHub.uuid) {
      setSouthUuid(superHub.uuid);
    }
  }, [superHub]);

  useEffect(() => {
    if (spaceId > 0 && superHub) {
      setSpaceId(spaceId);
      dispatch(
        patchField(String(superHub.networkGroupId), 'spaceId', superHub.spaceId)
      );
    }
  }, [spaceId, superHub]);

  if (lockSiteId && siteId === -1) {
    onChange('siteId', lockSiteId);
  }

  const handleEdgeControlDeviceChange = (value: EdgeDeviceModel) => {
    onChange('edge.edgeDevice', value);
    if (value === 'EB_SUPER_HUB') {
      if (originalNetworkGroup) {
        // When editing SuperHub, site and serial # should not be changed
        // so reset to original values
        onChange('edge.serialNumber', originalNetworkGroup.edge?.serialNumber);
        handleSiteIdChange(originalNetworkGroup.siteId);
      }

      // repopulated uuid
      if (!northUuid) setNorthUuid(edge.uuid);

      dispatch(newGateway(String(siteIdInState)));
    }
  };

  const handleSerialNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;

    if (iotodState?.formErrorsVisible || iotodState?.macAddress) {
      dispatch(renderNewIotodFields());
    }

    setSerialNumberValue(value);
    onChange('edge.serialNumber', value);
  };

  const setSouthboundUUID = value => {
    setSouthUuid(value);
    onChange('southUuid', value);
    if (updateHub) {
      if (!superHub) {
        dispatch(updateHub('new', 'uuid', value));
      } else {
        dispatch(updateField(String(superHub?.id), 'southUuid', value));
        dispatch(updateHub(String(superHub?.id), 'uuid', value));
      }
    }
  };

  const handleMacAddressChange = (
    event: ChangeEvent<HTMLInputElement>,
    target: string
  ) => {
    const value = event?.currentTarget?.value;
    // Format value to be in the form of XX:XX:XX:XX:XX:XX
    let formattedValue =
      value
        ?.replace(/[^0-9A-Fa-f]/g, '')
        ?.slice(0, 12)
        ?.toUpperCase()
        ?.match(/.{1,2}/g)
        ?.join(':') || '';
    if (iotodState?.macAddress) {
      dispatch(renderNewIotodFields());
    }

    if (isForSuperHub) {
      if (target === 'northBridge') {
        setNorthUuid(formattedValue);
        setFormattedUuid(formattedValue);
        // if northUuid, fire onChange in useEffect instead
      }
      if (target === 'southBridge') {
        setSouthboundUUID(formattedValue);
      }
    } else {
      setFormattedUuid(formattedValue);
      onChange('edge.uuid', formattedValue);
    }
  };

  const handleMacAddressLookup = () => {
    dispatch(clearFormErrors());
    if (edge?.edgeDevice === 'EB_SUPER_HUB') {
      if (northUuid !== undefined && siteIdInState !== -1)
        dispatch(getBalenaDeviceByUUID(northUuid, siteIdInState));
    } else {
      const validSerialNumber = isValidSerialNumber(serialNumber);

      if (!validSerialNumber) {
        dispatch(setInvalidSerialNumberError());
        return;
      }

      setSerialNumberValue(serialNumber);
      dispatch(lookupMacAddress(serialNumber));
    }
  };

  const handleSiteIdChange = (value: number) => {
    setSiteId(value);
    if (updateHub) {
      dispatch(updateHub('new', 'uuid', southUuid));
    }
    onChange('siteId', value);
  };

  const handleSpaceIdChange = (value: number) => {
    setSpaceId(value);
    onChange('spaceId', value);
  };

  const renderLookUpButton = () => {
    return (
      <>
        {iotodState?.macAddressIsLoading || lookupMACAddressLoading ? (
          <div className={styles.loader}>
            <Loader size={18} variant="default" />
          </div>
        ) : (
          <>
            <Button
              onClick={handleMacAddressLookup}
              disabled={isLookupAddressButtonDisabled}
              className={styles.macAddressLookUpButton}
              size="medium"
              variant="outlined"
            >
              {LOOKUP_MAC_ADDRESS}
            </Button>
          </>
        )}
      </>
    );
  };

  const isLookupAddressButtonDisabled =
    edge?.edgeDevice === 'EB_SUPER_HUB'
      ? !northUuid?.length ||
        !isValidUUID(northUuid) ||
        siteIdInState === undefined ||
        siteIdInState === -1
      : (!serialNumber?.length || serialNumber?.length !== 11) &&
        (!edge?.serialNumber?.length || edge?.serialNumber!?.length !== 11);

  const isLatestBuild = () => {
    if (superHub && runningRelease && latestRelease) {
      const runningReleaseVersion = runningRelease.split('-')[0];
      const latestReleaseVersion = latestRelease.split('-')[0];
      return runningReleaseVersion === latestReleaseVersion;
    }
    return true;
  };

  return (
    <>
      <ModalFormContent>
        <div>
          <Label required>Name</Label>
        </div>
        <div>
          <InputField
            aria-label="title"
            type="text"
            name="title"
            autoComplete="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
            customErrorText={formErrors['title'] && formErrors['title'][0]}
          />
        </div>

        <div>
          <Label>Description</Label>
        </div>
        <div>
          <InputField
            aria-label="description"
            type="text"
            name="description"
            value={description}
            autoComplete="description"
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label required>Edge Control Device</Label>
        </div>
        <div>
          <SelectEdgeControlDevice
            value={edge.edgeDevice}
            onSelect={value => handleEdgeControlDeviceChange(value)}
            error={formErrorsVisible && !!formErrors['edge.edgeDevice']}
          />
        </div>
        {/* For SuperHub, when creating new Network Group, hide the field */}
        {/* When editing, hide the field only when serialNumber is empty */}
        {(!isForSuperHub || (originalNetworkGroup && edge.serialNumber)) && (
          <>
            <div>
              <Label required>Serial Number</Label>
            </div>
            <div>
              <InputField
                type="text"
                aria-label="serial number"
                name="edge.serialNumber"
                value={edge && edge.serialNumber}
                autoComplete="serialNumber"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSerialNumberChange(e)
                }
                disabled={isForSuperHub} // Not allow to change serial number
                error={
                  (formErrorsVisible && !!formErrors['edge.serialNumber']) ||
                  (iotodState?.formErrorsVisible &&
                    !!iotodState?.formErrors?.['edge.serialNumber'])
                }
                customErrorText={
                  (formErrorsVisible &&
                    formErrors['edge.serialNumber'] &&
                    formErrors['edge.serialNumber'][0]) ||
                  (iotodState?.formErrors?.['edge.serialNumber'] &&
                    iotodState?.formErrors?.['edge.serialNumber']?.[0])
                }
              />
            </div>
          </>
        )}

        {!isForSuperHub && (
          <>
            <div>
              <Label required>MAC address (UUID)</Label>
            </div>
            <div className={styles.macAddressField}>
              <InputField
                aria-label="Edge UUID"
                className={styles.macAddressInput}
                type="text"
                name="edge.uuid"
                value={formattedUuid}
                autoComplete="uuid"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleMacAddressChange(e, 'edge')
                }
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  handleMacAddressChange(e, 'edge')
                }
                error={formErrorsVisible && !!formErrors['edge.uuid']}
                customErrorText={
                  formErrors['edge.uuid'] && formErrors['edge.uuid'][0]
                }
              />

              {renderLookUpButton()}
            </div>
          </>
        )}

        {isForSuperHub && (
          <>
            <div>
              <Label required>MAC NORTH (UUID)</Label>
            </div>
            <div>
              <InputField
                aria-label="Northbound UUID"
                className={styles.macAddressInput}
                type="text"
                name="northUuid"
                value={northUuid}
                autoComplete="uuid"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleMacAddressChange(e, 'northBridge')
                }
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  handleMacAddressChange(e, 'northBridge')
                }
                error={
                  (formErrorsVisible && !!formErrors['northUuid']) ||
                  lookupMACAddressError
                }
                customErrorText={
                  lookupMACAddressError
                    ? `UUID entered could not be found`
                    : formErrors['northUuid'] && formErrors['northUuid'][0]
                }
              />
            </div>

            <div>
              <Label required>MAC SOUTH (UUID)</Label>
            </div>
            <div className={styles.macAddressField}>
              <InputField
                aria-label="Southbound UUID"
                className={styles.macAddressInput}
                type="text"
                name="southUuid"
                value={southUuid}
                autoComplete="uuid"
                disabled={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleMacAddressChange(e, 'southBridge')
                }
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  handleMacAddressChange(e, 'southBridge')
                }
                error={formErrorsVisible && !!formErrors['southUuid']}
                customErrorText={
                  formErrors['southUuid'] && formErrors['southUuid'][0]
                }
              />
              {renderLookUpButton()}
            </div>
          </>
        )}

        {isForSuperHub && superHub && runningRelease && (
          <>
            <div>
              <Label>Build Version</Label>
            </div>
            <div className={styles.buildVersionField}>
              <InputField
                aria-label="Build Version"
                type="text"
                name="edge.buildVersion"
                value={runningRelease.split('-')[0]}
                error={formErrorsVisible && !!formErrors['edge.buildVersion']}
                customErrorText={
                  formErrors['edge.buildVersion'] &&
                  formErrors['edge.buildVersion'][0]
                }
                disabled={true}
              />
              {!isLatestBuild() && !isUpdating && (
                <div className={styles.warning}>
                  <WarningIcon size={16} />
                  <p>
                    <b>Update Firmware</b> Not the latest release version
                  </p>
                </div>
              )}
              {isUpdating && (
                <div className={styles.updating}>
                  <CircularProgress size={28} />
                  <span>
                    <b>Updating...</b>
                  </span>
                  <span>{updateProgress && `${updateProgress}%`}</span>
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <Label required>Site</Label>
        </div>
        <div>
          <SelectSite
            disabled={!!lockSiteId}
            onSelect={value => handleSiteIdChange(value)}
            value={siteIdInState}
            error={formErrorsVisible && !!formErrors.siteId}
            customErrorText={formErrors['siteId'] && formErrors['siteId'][0]}
          />
        </div>

        {isForSuperHub && (
          <>
            <div>
              <Label required>Space</Label>
            </div>
            <div>
              <SelectSpace
                allowCreateNew
                siteId={siteIdInState}
                onSelect={value => handleSpaceIdChange(value)}
                value={spaceIdInState}
                error={formErrorsVisible && !!formErrors.spaceId}
                customErrorText={
                  formErrors['spaceId'] && formErrors['spaceId'][0]
                }
              />
            </div>
          </>
        )}
      </ModalFormContent>

      <ModalFormContent>
        <FormText>* Mandatory fields</FormText>
      </ModalFormContent>
    </>
  );
};

export default EditNetworkGroupForm;
