import {
  Label,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { Equipment, Vendor } from '@energybox/react-ui-library/dist/types';
import { HvacType } from '@energybox/react-ui-library/dist/types/Gateway';
import {
  createTemperatureString,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import React, { useEffect } from 'react';
import ModalFormContent from '../ModalFormContent';
import {
  Thermostat,
  ThermostatSettingsLabel,
  ThermostatStatusLabel,
} from '@energybox/react-ui-library/dist/types/Thermostat';
import {
  Card,
  CardContent,
  CardTitle,
} from '@energybox/react-ui-library/dist/components';
import { ApplicationState } from '../../reducers';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  useSubscribeToEntities,
  useSubscribeToRFThermostat,
} from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import {
  subscribeToDeviceReadings,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import {
  getThermostatDetailById,
  getThermostatsBySiteId,
} from '../../actions/thermostats';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { useGetGatewaysByNetworkGroupById } from '../../hooks/useGetGateways';
import { EditThermostat } from '../../types/Thermostat';
import {
  ThermostatCoolMode,
  ThermostatDehumidify,
  ThermostatHeatMode,
  ThermostatHumidify,
  ThermostatHumidifierIdleMode,
  ThermostatIdleMode,
  RemoteAccess,
} from '@energybox/react-ui-library/dist/icons';
import { ThermostatFanActive } from '@energybox/react-ui-library/dist/icons';
import ThermostatFanIdle from '@energybox/react-ui-library/dist/icons/Thermostat/ThermostatFanIdle';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import WhitelistTable from '../../containers/Gateways/WhitelistTable';
import { SignalStatus } from '../../utils/sensor';
import { getSensors } from '../../actions/sensors';
import {
  CoolingLabel,
  HeatingLabel,
  HumidityModeLabel,
  IdleLabel,
  SensorSourceMap,
  formatHumidRange,
  HumidityStateLabel,
  FAN_STATE,
} from './ThermostatStatusUtils';
import styles from './ThermostatStatus.module.css';
import { useIsStreamConnected } from '../../hooks/streamApi/useIsStreamConnected';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import {
  formatValueAsTemp,
  getTempDifferenceByUnit,
  getUserPreferenceTemperatureUnit,
} from '@energybox/react-ui-library/dist/utils/temperature';
import { MapTemperatureUnitApiValuesToDisplayUnit } from '@energybox/react-ui-library/dist/types/Global';
import { getNetworkGroup } from '../../actions/network_groups';
import { getBalenaStatusBySiteId } from '../../actions/balena';
import RemoteAccessButton from '../RemoteAccessButton/RemoteAccessButton';

type Props = {
  fields: EditThermostat;
  equipment?: Equipment;
  isEbThermostat?: boolean;
  deviceStatusById: DeviceStatusById;
  getSensors: typeof getSensors;
  thermostatModel?: string | null;
};

/**
 * Component only intended for Energybox Thermostat Edit View
 * @param
 * @returns
 */
const ThermostatStatus: React.FC<Props> = ({ fields }) => {
  const { title, networkGroupId, siteId } = fields;

  const gateways = useGetGatewaysByNetworkGroupById(networkGroupId);
  const user = useCurrentUser();
  const gateway = gateways?.find(gateway => {
    return gateway?.title === title;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getThermostatsBySiteId(siteId));
  }, [dispatch, siteId]);

  function formatTimeWithUnits(time) {
    if (!isDeviceOnline) {
      return global.NOT_AVAILABLE;
    }
    if (time === 1) {
      return `${time} min`;
    } else if (time === 0 || time > 1) {
      return `${time} mins`;
    } else {
      return global.NOT_AVAILABLE;
    }
  }

  function getOperatingModeLabel() {
    if (isCoolingStage1Active === true) {
      return CoolingLabel.Stage1;
    } else if (isCoolingStage2Active === true) {
      return CoolingLabel.Stage2;
    } else if (isHeatingStage1Active === true) {
      return HeatingLabel.Stage1;
    } else if (isHeatingStage2Active === true) {
      return HeatingLabel.Stage2;
    } else if (isIdling === true) {
      return IdleLabel;
    } else {
      return global.NOT_AVAILABLE; // Default case if none of the conditions are met
    }
  }

  //To dispatch device readings
  // we need the selectorFn, gateway object
  // result is found in the subscribedThermostats.byRFId
  //assumes thermostats are fetched in parent component
  const thermostat: Thermostat | undefined = useSelector(
    ({ thermostats }: ApplicationState) => {
      if (gateway) {
        return thermostats.thermostatsById[gateway?.id];
      }
    }
  );

  const thermostatsByRFIdSelectorFn = useSelector(
    ({ subscribedThermostats }: ApplicationState) => {
      if (thermostat && subscribedThermostats.byRFId) {
        return subscribedThermostats.byRFId[thermostat.id];
      }
      return undefined;
    }
  );

  useEffect(() => {
    if (thermostat?.id) {
      dispatch(getThermostatDetailById(thermostat?.id));
    }
  }, [dispatch, thermostat?.id]);

  const thermostatDetails = useSelector(
    ({ thermostats }: ApplicationState) => thermostats.thermostatDetailById
  );

  const thermostatDetail = thermostatDetails[thermostat?.id || ''];

  const subscribedRFThermostat = useSubscribeToRFThermostat(
    thermostat,
    () => thermostatsByRFIdSelectorFn,
    dispatch,
    subscribeToDeviceReadings,
    unsubscribeFromDeviceReadings
  );

  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  const reversingValve = subscribedRFThermostat?.reversingValve;

  const heatingSystem = subscribedRFThermostat?.heatingSystem;

  const hvacType = subscribedRFThermostat?.hvacType;

  const isHeatPump = hvacType
    ? hvacType === HvacType.HEAT_PUMP ||
      hvacType === HvacType.HP_HEAT_ONLY ||
      hvacType === HvacType.HP_COOL_ONLY ||
      hvacType === HvacType.TWO_STAGE_HEAT ||
      hvacType === HvacType.SINGLE_STAGE_COOL_HEAT ||
      hvacType === HvacType.TWO_STAGE_COOL_HEAT ||
      hvacType === HvacType.HP_SINGLE_STAGE_COOL_HEAT ||
      hvacType === HvacType.HP_SINGLE_STAGE_COOL_ONLY ||
      hvacType === HvacType.HP_SINGLE_STAGE_HEAT_ONLY ||
      hvacType === HvacType.HP_TWO_STAGE_COOL_HEAT ||
      hvacType === HvacType.HP_TWO_STAGE_COOL_ONLY ||
      hvacType === HvacType.HP_TWO_STAGE_HEAT_ONLY
    : false;

  const isConventional = hvacType
    ? hvacType === HvacType.HEAT_ONLY ||
      hvacType === HvacType.COOL_OR_HEAT ||
      hvacType === HvacType.CS_SINGLE_STAGE_COOL_HEAT ||
      hvacType === HvacType.CS_SINGLE_STAGE_COOL_ONLY ||
      hvacType === HvacType.CS_SINGLE_STAGE_HEAT_ONLY ||
      hvacType === HvacType.CS_TWO_STAGE_COOL_HEAT ||
      hvacType === HvacType.CS_TWO_STAGE_COOL_ONLY ||
      hvacType === HvacType.CS_TWO_STAGE_HEAT_ONLY
    : false;

  const isTwoStages = [
    HvacType.CS_TWO_STAGE_COOL_HEAT,
    HvacType.CS_TWO_STAGE_COOL_ONLY,
    HvacType.CS_TWO_STAGE_HEAT_ONLY,
    HvacType.HP_TWO_STAGE_COOL_HEAT,
    HvacType.HP_TWO_STAGE_COOL_ONLY,
    HvacType.HP_TWO_STAGE_HEAT_ONLY,
  ].includes(hvacType as HvacType);

  const hvacTypeString = hvacType || global.NOT_AVAILABLE;

  const isDeviceOnline =
    thermostat &&
    deviceStatusById &&
    deviceStatusById[thermostat.id]?.onlineState === true;

  const iconSize = 18;
  const fanIdleMode = (
    <ThermostatFanIdle size={iconSize} color={`var(--ambient-basePlus75)`} />
  );
  const fanActiveMode = (
    <ThermostatFanActive size={iconSize} color={`var(--lemon-basePlus30)`} />
  );

  const thermostatIdleMode = (
    <ThermostatIdleMode
      size={iconSize}
      width={'31'}
      height={'18'}
      color={`var(--ambient-basePlus75)`}
    />
  );
  const thermostatCoolMode = <ThermostatCoolMode size={iconSize} />;
  const thermostatHeatMode = <ThermostatHeatMode size={iconSize} />;

  const humidifyOnMode = (
    <ThermostatHumidify size={iconSize} color={`var(--blue-baseMinus30)`} />
  );
  const deHumidifyOnMode = <ThermostatDehumidify size={iconSize} />;
  const humidifyOffMode = (
    <ThermostatHumidifierIdleMode
      size={iconSize}
      color={`var(--ambient-basePlus75)`}
    />
  );

  const sensorSourceLabel = (bit: number | undefined): string => {
    return isDeviceOnline && isDefined(bit)
      ? SensorSourceMap[bit]
      : global.NOT_AVAILABLE;
  };

  const getSensorSourceFallbackSequence = (
    sequence: number[] | undefined
  ): string => {
    return isDeviceOnline && sequence
      ? sequence.map(sensorSourceLabel).join(' / ')
      : global.NOT_AVAILABLE;
  };
  const isCoolingStage1Active =
    isDefined(subscribedRFThermostat) &&
    subscribedRFThermostat.coolingStage1Running;
  const isCoolingStage2Active =
    isDefined(subscribedRFThermostat) &&
    subscribedRFThermostat.coolingStage2Running;
  const isCoolingActive = isCoolingStage1Active || isCoolingStage2Active;
  const isHeatingStage1Active =
    isDefined(subscribedRFThermostat) &&
    subscribedRFThermostat.heatingStage1Running;
  const isHeatingStage2Active =
    isDefined(subscribedRFThermostat) &&
    subscribedRFThermostat.heatingStage2Running;
  const isHeatingActive = isHeatingStage1Active || isHeatingStage2Active;
  const isIdling =
    isDefined(subscribedRFThermostat) && !isCoolingActive && !isHeatingActive;

  function getThermostatMode() {
    if (isCoolingActive) {
      return thermostatCoolMode;
    } else if (isHeatingActive) {
      return thermostatHeatMode;
    } else if (isIdling) {
      return thermostatIdleMode;
    } else {
      return '';
    }
  }

  function getHumidityIcon(humidityMode) {
    if (humidityMode === 'HUMIDIFY') {
      return humidifyOnMode;
    } else if (humidityMode === 'DEHUMIDIFY') {
      return deHumidifyOnMode;
    } else if (humidityMode === 'HUMIDITY_OFF') {
      return humidifyOffMode;
    } else {
      return global.NOT_AVAILABLE;
    }
  }

  function getHumidityString(humidity) {
    if (isDefined(humidity)) {
      return ' @ ' + humidity.toFixed(1) + ' % ';
    }
    return '';
  }

  function getHumidityModeLabel(mode) {
    return mode && isDeviceOnline
      ? HumidityModeLabel(mode)
      : global.NOT_AVAILABLE;
  }

  function getHumiditySetPoint(mode, humidifySetPoint, dehumidifySetPoint) {
    return formatHumidRange(mode, humidifySetPoint, dehumidifySetPoint);
  }

  function getDeadbandValueByUserPreference(tempUnit, value, user, thermostat) {
    const {
      gatewayInfo: { firmwareVersion },
    } = thermostat;
    const firmware = firmwareVersion.split('.');
    // altering the display value based on firmware version
    const alteredValue = (firmware[2] as number) >= 169 ? value / 10 : value;
    return MapTemperatureUnitApiValuesToDisplayUnit[tempUnit] !==
      getUserPreferenceTemperatureUnit(user)
      ? formatValueAsTemp(
          getTempDifferenceByUnit(
            alteredValue,
            MapTemperatureUnitApiValuesToDisplayUnit[tempUnit]
          ).toFixed(1),
          user
        ) || global.NOT_AVAILABLE
      : formatValueAsTemp(alteredValue, user);
  }

  // superhub adminportal remote access
  const networkGroup = useSelector(
    (state: ApplicationState) =>
      state.networkGroups.networkGroupsById[networkGroupId]
  );

  useEffect(() => {
    dispatch(getNetworkGroup(networkGroupId));
  }, [dispatch, networkGroupId]);

  const serialNumber =
    networkGroup?.edge && networkGroup.edge.edgeDevice === 'EB_SUPER_HUB'
      ? networkGroup.edge.serialNumber
      : null;

  const statusByUuid = useSelector(
    (state: ApplicationState) => state.balena.statusByUuid
  );

  useEffect(() => {
    if (serialNumber) {
      dispatch(getBalenaStatusBySiteId(siteId));
    }
  }, [dispatch, serialNumber, siteId]);

  const status = serialNumber ? statusByUuid[serialNumber] : null;

  const uuid = gateway?.uuid;
  const subscribeToStatus = (
    vendor: string,
    uuid: string,
    id: string | number
  ) => dispatch(subscribeToDeviceStatus(vendor, uuid, id));

  const unsubscribeFromStatus = (
    vendor: string,
    uuid: string,
    id: string | number
  ) => dispatch(unsubscribeFromDeviceStatus(vendor, uuid, id));
  const streamConnected = useIsStreamConnected();

  let devicesToSubscribeTo: EntityInfoToSub[] = [];

  if (thermostat?.id && uuid) {
    devicesToSubscribeTo = [
      {
        id: thermostat?.id,
        uuid: uuid,
        vendor: Vendor.ENERGYBOX,
      },
    ];
  }
  useSubscribeToEntities(
    devicesToSubscribeTo,
    subscribeToStatus,
    unsubscribeFromStatus,
    streamConnected
  );

  const renderThermostatTemperature = () => {
    let readingIsInvalid =
      subscribedRFThermostat?.temperature == 0 ||
      subscribedRFThermostat?.temperature == 0.0;
    if (readingIsInvalid)
      readingIsInvalid = [1, 2, 4].includes(
        Number(subscribedRFThermostat?.temperatureSensorStatus) % 32
      );

    return isDefined(subscribedRFThermostat) &&
      isDeviceOnline &&
      isDefined(user) &&
      isDefined(subscribedRFThermostat?.temperature) &&
      !readingIsInvalid
      ? createTemperatureString(subscribedRFThermostat.temperature, user)
      : global.NOT_AVAILABLE;
  };

  return (
    <Card>
      <CardContent>
        <div className={styles.statusAndSettingsContainer}>
          <div className={styles.tstatStatusContainer}>
            <div className={styles.leftContainer}>
              <CardTitle className={styles.cardTitle}>Live Status</CardTitle>
            </div>
            <div className={styles.middleContainer}>
              <ModalFormContent>
                <div>
                  <Label>{ThermostatStatusLabel.STATUS}</Label>
                </div>
                <div className={styles.thermostatSubTextLabel}>
                  <strong>
                    <span
                      className={
                        isDeviceOnline ? styles.online : styles.offline
                      }
                    >
                      {isDeviceOnline ? 'Online' : 'Offline'}
                    </span>
                  </strong>
                  <span></span>
                  <span className={styles.thermostatSubTextLabel}>
                    {isDefined(uuid) &&
                      isDefined(deviceStatusById) &&
                      isDefined(deviceStatusById[uuid]) && (
                        <TimeDistance
                          timestamp={
                            deviceStatusById[uuid]?.onlineStateUpdatedAt
                          }
                        />
                      )}
                  </span>
                </div>

                <div>
                  <Label>{ThermostatStatusLabel.SIGNAL}</Label>
                </div>
                {isDefined(uuid) &&
                isDefined(deviceStatusById) &&
                isDefined(deviceStatusById[uuid]) &&
                isDeviceOnline &&
                isDefined(deviceStatusById[uuid].signalStrength) ? (
                  <div className={styles.signalStrengthLabel}>
                    {`${deviceStatusById[uuid]?.signalStrength}dBm`}
                    <div>
                      <SignalStatus
                        vendor={Vendor.ENERGYBOX}
                        sensorStatus={deviceStatusById[uuid]}
                        showSignalStrength={true}
                        showLabel={false}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.thermostatFormLabel}>
                    {global.NOT_AVAILABLE}
                  </div>
                )}
              </ModalFormContent>
            </div>

            <div className={styles.rightContainer}>
              <ModalFormContent>
                <div>
                  <Label>{ThermostatStatusLabel.THERMOSTAT_STATUS}</Label>
                </div>
                <div className={styles.thermostatFormLabel}>
                  {renderThermostatTemperature()}
                  {isDefined(subscribedRFThermostat) && isDeviceOnline
                    ? getHumidityString(subscribedRFThermostat?.humidity)
                    : ' @ ' + global.NOT_AVAILABLE + ' % '}
                </div>

                <div></div>

                <span className={styles.thermostatSubTextLabel}>
                  <div></div>
                  <span style={{ display: 'inline-flex' }}>
                    {isDeviceOnline && isDefined(subscribedRFThermostat) ? (
                      <>
                        <span>{getThermostatMode()}</span>
                        <span className={classNames(styles.paddingLeft)}>
                          {getOperatingModeLabel()}
                        </span>
                      </>
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </span>

                  <div className={styles.emptyContentRow}></div>
                  <span style={{ display: 'inline-flex' }}>
                    {isDeviceOnline && isDefined(subscribedRFThermostat) ? (
                      <>
                        <span>
                          {subscribedRFThermostat?.fanRunning
                            ? fanActiveMode
                            : fanIdleMode}
                        </span>
                        <span className={styles.paddingLeft}>
                          {subscribedRFThermostat.fanRunning
                            ? FAN_STATE.FAN_ACTIVE
                            : FAN_STATE.FAN_INACTIVE}
                        </span>
                      </>
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </span>

                  <div className={styles.emptyContentRow}></div>
                  <div className={styles.emptyContentRow}></div>
                  <span style={{ display: 'inline-flex' }}>
                    {isDeviceOnline &&
                    isDefined(subscribedRFThermostat) &&
                    isDefined(subscribedRFThermostat?.humidityState) ? (
                      <>
                        <span className={classNames(styles.modeRow)}>
                          {getHumidityIcon(
                            subscribedRFThermostat?.humidityState
                          )}
                        </span>
                        <span className={classNames(styles.paddingLeft)}>
                          <span>
                            {HumidityStateLabel(
                              subscribedRFThermostat?.humidityState
                            )}
                          </span>
                        </span>
                      </>
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </span>
                </span>
              </ModalFormContent>
            </div>

            {isDefined(serialNumber) && isDefined(status) && (
              <RemoteAccessButton
                serialNumber={serialNumber}
                balenaStatus={status}
                path={
                  '/thermostats/' +
                    uuid
                      ?.split(':')
                      .join('')
                      .toLowerCase() || ''
                }
              />
            )}
          </div>

          <div className={styles.tstatSettingsContainer}>
            <div className={styles.leftContainer}>
              <CardTitle className={styles.cardTitle}>Settings</CardTitle>
            </div>
            <div className={styles.middleContainer}>
              <ModalFormContent className={styles.thermostatFormContent}>
                <div>
                  <Label>{ThermostatSettingsLabel.DISPLAY_NAME}</Label>
                </div>
                <div className={styles.thermostatFormLabel}>
                  {thermostatDetail?.configuration?.equipmentName ||
                    global.NOT_AVAILABLE}
                </div>
                <div>
                  <Label>{ThermostatSettingsLabel.SENSOR_SOURCE}</Label>
                </div>
                <div className={styles.thermostatFormLabel}>
                  {sensorSourceLabel(
                    subscribedRFThermostat?.temperatureSensorSource
                  )}
                </div>
                <div>
                  <Label>{ThermostatSettingsLabel.WIRELESS_SENSORS}</Label>
                </div>
                <div></div>
                {isDefined(subscribedRFThermostat) && (
                  <WhitelistTable
                    isLoading={false}
                    isThermostatSensorPage={true}
                    list={subscribedRFThermostat?.dotList}
                    onRemoveSensor={() => {}}
                    onSensorAdd={() => {}}
                  />
                )}
              </ModalFormContent>
            </div>
            <div className={styles.rightContainer}>
              <ModalFormContent>
                <div>
                  <Label>
                    {ThermostatSettingsLabel.SENSOR_SOURCE_FALLBACK}{' '}
                  </Label>
                </div>
                <div className={styles.thermostatFormLabel}>
                  {getSensorSourceFallbackSequence(
                    subscribedRFThermostat?.temperatureSensorFallbackSequence
                  )}
                </div>
                <div>
                  <Label>
                    {ThermostatSettingsLabel.SENSOR_SOURCE_FALLBACK_TIME}{' '}
                  </Label>
                </div>
                <div className={styles.thermostatFormLabel}>
                  {formatTimeWithUnits(
                    subscribedRFThermostat?.temperatureSensorFallbackTime
                  )}
                </div>
                <div>
                  <Label>System Type</Label>
                </div>
                <div className={styles.thermostatFormLabel}>
                  {(isDeviceOnline && hvacTypeString) || global.NOT_AVAILABLE}{' '}
                </div>

                {isHeatPump && (
                  <>
                    <div>
                      <Label>Reversing Valve (Heatpump-only)</Label>
                    </div>
                    <div className={styles.thermostatFormLabel}>
                      {(isDeviceOnline && reversingValve) ||
                        global.NOT_AVAILABLE}
                    </div>
                  </>
                )}
                {isDeviceOnline && isConventional && (
                  <>
                    <div>
                      <Label>Heating System </Label>
                    </div>
                    <div className={styles.thermostatFormLabel}>
                      {isDefined(heatingSystem) && isDeviceOnline
                        ? heatingSystem
                        : global.NOT_AVAILABLE}
                    </div>
                  </>
                )}

                <div>
                  <Label>Deadband Setting</Label>
                </div>

                <div className={styles.thermostatSubTextLabel}>
                  <div className={styles.emptyRow}></div>
                  <div>
                    {' '}
                    Stage 1 :{' '}
                    {isDefined(subscribedRFThermostat) &&
                    isDefined(user) &&
                    isDeviceOnline &&
                    isDefined(subscribedRFThermostat?.deadbandStage1) &&
                    subscribedRFThermostat?.tempUnits !== undefined
                      ? getDeadbandValueByUserPreference(
                          subscribedRFThermostat?.tempUnits,
                          subscribedRFThermostat?.deadbandStage1,
                          user,
                          thermostat
                        )
                      : global.NOT_AVAILABLE}
                  </div>
                  {isTwoStages && (
                    <div>
                      {' '}
                      Stage 2 :{' '}
                      {isDefined(subscribedRFThermostat) &&
                      isDefined(user) &&
                      isDeviceOnline &&
                      isDefined(subscribedRFThermostat?.deadbandStage2) &&
                      subscribedRFThermostat?.tempUnits !== undefined
                        ? getDeadbandValueByUserPreference(
                            subscribedRFThermostat?.tempUnits,
                            subscribedRFThermostat?.deadbandStage2,
                            user,
                            thermostat
                          )
                        : global.NOT_AVAILABLE}
                    </div>
                  )}
                </div>
                <div>
                  <Label>{ThermostatSettingsLabel.COMPRESSOR_PROTECTION}</Label>
                </div>
                {isDefined(subscribedRFThermostat) &&
                isDefined(subscribedRFThermostat?.compressorProtectionTime) &&
                subscribedRFThermostat?.compressorProtectionTime <= 10 ? (
                  <div className={styles.thermostatFormLabel}>
                    <div>
                      {formatTimeWithUnits(
                        subscribedRFThermostat?.compressorProtectionTime
                      ) || global.NOT_AVAILABLE}
                    </div>
                  </div>
                ) : (
                  <div className={styles.thermostatSubTextLabel}>
                    <div className={styles.emptyRow}></div>
                    <div>
                      {' '}
                      Stage 1 :{' '}
                      {isDefined(subscribedRFThermostat) &&
                      isDeviceOnline &&
                      isDefined(
                        subscribedRFThermostat?.compressorProtectionTime
                      )
                        ? formatTimeWithUnits(
                            Number(
                              subscribedRFThermostat?.compressorProtectionTime.toString(
                                16
                              )[1]
                            )
                          )
                        : global.NOT_AVAILABLE}
                    </div>
                    {isTwoStages && (
                      <div>
                        {' '}
                        Stage 2 :{' '}
                        {isDefined(subscribedRFThermostat) &&
                        isDeviceOnline &&
                        isDefined(
                          subscribedRFThermostat?.compressorProtectionTime
                        )
                          ? formatTimeWithUnits(
                              Number(
                                subscribedRFThermostat?.compressorProtectionTime.toString(
                                  16
                                )[0]
                              )
                            )
                          : global.NOT_AVAILABLE}
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <Label>Humidity Settings</Label>
                </div>
                <div className={styles.thermostatSubTextLabel}>
                  <div className={styles.emptyRow}></div>
                  <div>
                    {getHumidityModeLabel(subscribedRFThermostat?.humidityMode)}
                  </div>
                  <div></div>
                  {isDeviceOnline && isDefined(subscribedRFThermostat) && (
                    <>
                      {getHumiditySetPoint(
                        subscribedRFThermostat?.humidityMode,
                        subscribedRFThermostat?.humiditySetPoint,
                        subscribedRFThermostat?.dehumidifySetPoint
                      )}
                    </>
                  )}
                  <div className={styles.emptyRow}></div>
                  <div></div>
                </div>
              </ModalFormContent>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

//export default ThermostatStatus;
const mapStateToProps = ({
  deviceStatusById,
  sensors: { sensorsById },
}: ApplicationState) => ({
  deviceStatusById,
  sensorsById,
});

const mapDispatchToProps = {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
  getSensors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThermostatStatus);
